import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Experience from './pages/Experience';
import Blog from './pages/Blog';
import NavBar from "./components/Navbar";
import Footer from "./components/Footer";
import DisplayBlog from "./pages/DisplayBlog";



function App() {
  return (
    <div className="App">
      {/* <Router>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/experience" element={<Experience />} />
          <Route path="/blogs" element={<Blog />} />
          <Route path="/blogPost/:id" element={<DisplayBlog />} />
        </Routes>
        <Footer />
      </Router> */}
    </div>
  );
}

export default App;


